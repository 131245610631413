

































import { Vue, Component, Watch } from "vue-property-decorator";
import { newsDetailApi } from "@/api";
@Component({
    filters: {
        filterTime(val: any) {
            return val && val.slice(0, 10);
        },
        extract(content: String) {
            if (!content) return;
            content = content.replace(/<.*?>/g, "");
            return content.replace(/&.*?;/g, "");
        },
    },
})
export default class newsDetail extends Vue {
    @Watch("$route") routechange(to: any) {
        console.log(111);
        this.getNewsDetail();
    }
    public newsInfo = {};

    public mounted() {
        this.getNewsDetail();
    }

    public getNewsDetail() {
        // let json = this.$route.query.json
        // if (json) {
        //     this.newsInfo = json && JSON.parse(json as any)
        // }
        newsDetailApi({
            id: this.$route.query.id,
        }).then((res) => {
            this.newsInfo = res;
            console.log(this.newsInfo);
        });
    }

    public handelJump(item: any) {
        this.$router.push({ path: "/newsDetail", query: { id: item.id } });
        this.getNewsDetail();
    }
}
